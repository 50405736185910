import { createStore } from 'redux'

const initialState = {
    standardNotification: {},
    sidebarShow: 'responsive',
    enterprisesData: []
}
const reducer = (state = initialState, { payload ,type, ...rest }) => {
  switch (type) {
    case "STANDARD_NOTIFICATION":
        return Object.assign({}, state, {
          standardNotification: payload,
        });
    case 'set':
            return {...state, ...rest }
    
    default : return state
  }
}

const store = createStore(reducer);

export default store; 