/*eslint  import/no-anonymous-default-export:off  */
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
require("dotenv").config();

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const isAuthenticated = () => {
  return localStorage.getItem("token") && true;
}

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/dashboard' />
  )} />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);
// Containers
const Layout = React.lazy(() => import('./views/layouts/Layout'));

// Pages
const Login = React.lazy(() => import('./views/connexion/Login'));
const Page404 = React.lazy(() => import('./views/errorPage/Page404'));
const ForgotPassword = React.lazy(() => import ('./views/connexion/loginforgotPassword'));
class App extends Component {

  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} />
              <UnauthenticatedRoute exact path="/forgot-password" name="Forgot Passowrd" component={ForgotPassword} />
              <UnauthenticatedRoute exact path="/404" name="Page 404" component={Page404} />
              <AuthenticatedRoute path="/" name="Home" component={Layout} />
              <AuthenticatedRoute path="/logout" name="Logout" component={Layout} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
