import React, { Component } from "react";
import {
  CCol,
  CContainer,
  CRow
} from '@coreui/react'

export default class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: "",
      logsError: {},
    };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }
  componentDidCatch(error, info) {
    this.setState({
      errorInfo: error,
      logsError: info,
    });
  }
  render() {
    if (this.state.hasError) {
      return (


        <div className="c-app c-default-layout flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md="6">
                <span className="clearfix">
                  <h1 className="float-left display-3 mr-4">Oops!</h1>
                  <h4 className="pt-3">Something went wrong!</h4>
                  <p className="text-muted float-left">The page you are looking for is temporarily unavailable.</p>
                </span>
                <p className="semibold error_description">
                  Error:{this.state.errorInfo && this.state.errorInfo.toString()}
                </p>
                <p className="semibold error_description">
                  Details:{this.state.logsError.componentStack}
                </p>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      );
    }
    return this.props.children;
  }
}
